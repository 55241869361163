$primaryColor: #4389fa;
$backgroundColor: #f9fcff;
$primaryFontColor: #261c4b;
$inputFieldBackground: #f4f8ff;
$inputFieldBorder: #dee5ff;
$uploadBtnBackground: #fafcff;
$skilltagBg: #ebf3ff;
$badgeColor: #eb3729;
$boxShadow: #e3eeff;
$dashLine: #1466e8;
$secondaryFontColor:#1E1D1D;
$whiteColor:#ffffff;
$red_bg:#E25151A3;
$redTxt:#eb3c1e;
$subtext:#606060