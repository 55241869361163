.headerSelector.ant-select {
  border: none;
  color: white;
  .ant-select-selector {
    background: transparent !important;
    border: none !important;
    color: white !important;
  }
}
.ant-dropdown-menu-submenu-expand-icon {
  display: none;
}
.ant-dropdown-menu.ant-dropdown-menu-sub.ant-dropdown-menu-vertical {
  border-radius: 10px;
}

.details_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #347bee;
  border-radius: 8px;
  padding: 12px;
  margin-right: 32px;

  img {
    width: 24px;
    height: 24px;
  }
  .desctxt {
    width: 266px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin: 0px 10px;
  }
  .moewDetailsBtn {
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.01em;
    color: #4389fa;
    margin: 0;
    padding: 8px 16px;
    cursor: pointer;
    word-break: keep-all;
    white-space: nowrap;
  }
}

.logohide{
  display: none;
}


@media screen and (max-width:800px) {
  .details_btn{
    display: none;
  }
}