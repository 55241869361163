// @import "~antd/dist/antd.scss";
@import "antd/dist/antd.css";
@import "../styles/base.scss";

body {
  background-color: $backgroundColor;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  // .fr-box.fr-basic .fr-wrapper {
  //   border: none;
  // }
}

.ant-layout {
  background-color: transparent;
}

.ant-list-item {
  padding: 4px 0px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ant-notification-notice {
  box-shadow: none;
  padding: 5px 10px 3px;
  .ant-notification-notice-message {
    font-weight: 500;
    font-size: 14px;
    color: #261c4b;
  }
  .ant-notification-notice-icon {
    top: 18%;
  }
}
.ant-notification-notice-success {
  background: rgba(110, 191, 103, 0.1);
  border: 1px solid #6ebf67;
  border-radius: 8px;
}

.ant-notification-notice-error {
  background: rgba(226, 81, 81, 0.1);
  border: 1px solid #e25151;
  border-radius: 8px;
}

.ant-notification-notice-warning {
  background: #fff7e8;
  border: 1px solid #ff980e;
  border-radius: 8px;
}

.ant-notification-notice-close {
  top: 21%;
}

.ant-spin-nested-loading {
  height: 100%;
  .ant-spin-container {
    height: 100%;
  }
}

.ant-select-dropdown {
  border-radius: 10px;
}

// .ant-select-single:not(.ant-select-customize-input)
//   .ant-select-selector
//   .ant-select-selection-search-input {
//   height: 52px;
// }

// ol,
// ul {
//   padding: revert;
// }
