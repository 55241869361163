@import "../styles/base.scss";

.Common_input_container {
  // background: #f9fcff;
  // border: 1px solid rgba(67, 137, 250, 0.4);
  // border-radius: 8px;
  min-width: 32%;

  // padding: 5px 15px;
  // .label {
  //   font-weight: 400;
  //   font-size: 10px;
  //   color: #000000;
  // }
  .companyInput {
    background: transparent;
    width: 100%;
  }
  .companyInput.ant-select {
    border: none;
    background: transparent;
    width: 100%;
    .ant-select-selector {
      border: none;
      background: transparent;
      width: 100%;
      padding: 0px;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 0px;
  }

  input,
  textarea {
    background: white;
    border: 1px solid $inputFieldBorder;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 50px;
    padding-left: 15px;
    &:hover {
      background: white;
      border: 1px solid $inputFieldBorder;
    }
    &:focus {
      outline: none;
    }
  }
  textarea {
    padding: 20px;
  }
  .ant-select-selection-placeholder {
    color: gray;
    font-weight: 400;
  }
}

.industry_dropdown_conatiner {
  position: relative;
  width: 100%;
  .industry_options_conatiner {
    position: absolute;
    top: 50px;
    z-index: 2;
    right: 0;
    background: white;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    .industry_searchInput {
      width: 100%;
      padding: 5px;
      border: 1px solid rgba(67, 137, 250, 0.4);
      border-radius: 6px;
    }
    input {
      &:focus {
        outline: none;
      }
    }
    .industry_options {
      max-height: 200px;
      overflow: auto;
      .industry_option {
        padding: 5px;
        cursor: pointer;
      }
    }
  }
}
